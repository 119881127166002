window._ = require('lodash');
window.Popper = require('@popperjs/core');

try {
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Sortable = require('sortablejs/Sortable.js');

window.swal = require('sweetalert2');

window.FilePond = require('filepond');
window.FilePondPluginImagePreview = require('filepond-plugin-image-preview');
window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type');
window.FilePondPluginImageValidateSize = require('filepond-plugin-image-validate-size');
window.FilePondPluginFilePoster = require('filepond-plugin-file-poster');

window.Cropper = require('cropperjs');